import { ref } from '@vue/composition-api'
import router from '@/router'
import store from '@/store'
import moment from 'moment'
import { VALID_IMAGE } from '@/utils/config'

export default function addProduct() {
  const isReserved = ref(false)
  const isSpecial = ref(false)
  const isFree = ref(false)
  const isActive = ref(false)
  const isBanner = ref(false)
  const slug = ref('')
  const startTime = ref('')
  const endTime = ref('')
  const date = ref('')
  const type = ref(null)
  const category = ref(null)
  const weekDayAvailables = ref([])
  const imgID = ref(null)
  const imgSrc = ref('https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Example_image.svg/600px-Example_image.svg.png')
  const imgFile = ref(null)
  const defaultName = ref('')
  const defaultDescription = ref('')
  const defaultSubTite = ref('')
  const defaultReserveNote = ref('')
  const defaultReserveNoteCancel = ref('')
  const defaultAvailabelCancel = ref('')
  const defaultSpecialDetail = ref('')
  const arrayPrograms = ref([
    {
      name: '',
      languageCode: '',
      subTitle: '',
      description: '',
      reserveNote: '',
      reserveNoteCancel: '',
    },
  ])
  const countPrograms = ref()
  const idProgram = ref(null)
  const loading = ref(false)
  const numberDefault = ref(null)
  // Fetch post
  if (router.currentRoute.params.id) {
    loading.value = true
    idProgram.value = router.currentRoute.params.id
    store.dispatch('program/fetchCategories').then(() => {
      store
        .dispatch('program/fetchProgram', { id: idProgram.value })
        .then(response => {
          const res = response.body
          console.log(store.state.app.weekDayAvailableOptions)
          isReserved.value = res.canReserved
          isSpecial.value = res.isSpecial
          isFree.value = res.isFree
          isActive.value = res.isActive
          isBanner.value = res.isBanner
          startTime.value = res.startTime
          endTime.value = res.endTime
          if (res.inActiveDays) {
            weekDayAvailables.value = res.inActiveDays.map(x => ({
              label: store.state.app.weekDayAvailableOptions.find(y => x === y.value).label,
              value: x,
            }))
          }
          slug.value = res.slug
          date.value = `${moment(res.startDate).format('YYYY-MM-DD')} to ${moment(res.endDate).format('YYYY-MM-DD')}`
          category.value = {
            label: store.state.program.programCategories.find(x => x.value === res.categoryId)
              ? store.state.program.programCategories.find(x => x.value === res.categoryId).label : 'None',
            value: res.categoryId,
          }
          if (res.image) {
            imgSrc.value = VALID_IMAGE + res.image.key
            imgID.value = res.image.id
          }

          countPrograms.value = res.translations.length
          arrayPrograms.value = res.translations.map(x => ({
            name: x.name,
            languageCode: store.state.app.lanCodeOptions.find(e => e.value === x.languageCode),
            specialDetail: x.specialDetail,
            subTitle: x.subTitle,
            description: x.description,
            reserveNote: x.reserveNote,
            reserveNoteCancel: x.reserveNoteCancel,
            availabelCancel: x.availabelCancel,
            isDefaultLanguage: x.isDefaultLanguage,
          })).reverse()
          defaultName.value = res.name
          defaultSubTite.value = res.subTitle
          defaultDescription.value = res.description
          defaultReserveNote.value = res.reserveNote
          defaultReserveNoteCancel.value = res.reserveNoteCancel
          defaultAvailabelCancel.value = res.availabelCancel
          defaultSpecialDetail.value = res.specialDetail
          numberDefault.value = arrayPrograms.value.findIndex(x => x.isDefaultLanguage)
          if (numberDefault.value === -1) {
            numberDefault.value = null
          }
          loading.value = false
        })
    })
  }
  return {
    arrayPrograms,
    countPrograms,
    isReserved,
    startTime,
    endTime,
    date,
    type,
    imgID,
    imgSrc,
    imgFile,
    category,
    defaultName,
    defaultDescription,
    defaultSubTite,
    isSpecial,
    isFree,
    isActive,
    isBanner,
    slug,
    defaultSpecialDetail,
    loading,
    defaultReserveNote,
    defaultReserveNoteCancel,
    numberDefault,
    weekDayAvailables,
  }
}
